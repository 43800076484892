<template>
<div>
    <!-- <div class="ll-box">
      <div class="breadcrumb-top">
        <b-breadcrumb class="">
          <b-breadcrumb-item :to="{ name: 'dashboard' }">
            <feather-icon
              icon="HomeIcon"
              size="16"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ $t('Company') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active >
            {{ $t('Company list') }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div> -->
    <section>
        <AdvanceTable ref="advanceTable" url="admin/settings/organization/list" :height="window.height - 280" :columns="columns" :pageOptions="[50, 100, 200]" :defaultPageSize="50" @edit="openModalForEdit">
        <template #title>
            {{ $t("Company List") }}
        </template>
        <template #button>
            <b-button variant="info" class="mr-1" @click="openModalForCreate">
            <feather-icon icon="PlusSquareIcon" class="mr-50" />
            <span class="align-middle">{{ $t("Add Company") }}</span>
            </b-button>
        </template>

        <template #cell(requiredDim)="row">
            <b-form-checkbox :checked="row.value" class="custom-control-success" name="check-button" @change="updateStatus(row)" switch>
            <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
            </span>
            </b-form-checkbox>
        </template>
        </AdvanceTable>
    </section>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AdvanceTable from "@core/components/advanceTable/advanceTable.vue";
import {
  BFormCheckbox,BButton,BBreadcrumb,
  BBreadcrumbItem,
} from 'bootstrap-vue'
export default {
  components: {
    ToastificationContent,
    AdvanceTable,
    BFormCheckbox,BButton,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        { key: "orgCode", modelName: "org_code", label: "Org ID", width: "180", filtertype: "input", sortable: true },
        { key: "name", modelName: "name", label: "Label Name", width: "180", filtertype: "input", sortable: true },
        { key: "displayName", modelName: "display_name", label: "Company Name", filtertype: "input", sortable: true },
        { key: "salesName", modelName: "salesName", label: "Sales Name", filtertype: "input",width: "150", sortable: true },
        { key: "keAccountNumber", modelName: "keAccountNumber", label: "Fox Account",width: "150", filtertype: "input", sortable: true }, 
        { key: "orgPhone", modelName: "org_phone", label: "Company Phone", filtertype: "input", width: "180",sortable: true },
        { key: "orgType", modelName: "org_type", label: "Company Type", width: "150", filtertype: "input", sortable: true },
        { key: "createdAt", modelName: "createdAt", label: "Create At", width: "180", align: "center", filtertype: "datetime", sortable: true },
        { key: "requiredDim", modelName: "requiredDim", label: "Required Dim", width: "95", align: "center", filtertype: "select", options: { true: "Yes", false: "No" } }
      ]
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    openModalForCreate() {
      this.$router.push({ name: "customer-detail2", params: { id: 0,} });
    },
    openModalForEdit(item) {
      this.$router.push({ name: "customer-detail2", params: { id: item.id,orgCode:item.orgCode,name:item.name} });
    },
    updateStatus(item) {
    }
  }
};
</script>

<style scoped></style>
